// Import the necessary styles globally
import '@telegram-apps/telegram-ui/dist/styles.css';

// Import components from the library
import { AppRoot, List, Divider } from '@telegram-apps/telegram-ui';
import ArticleDeli from './articles/article_deli';
import ArticleMTProxy from './articles/article_mtproxy';
import Body from './body';
import ArticleTelegrahf from './articles/article_telegraph';


export const App = () => (
  <AppRoot>
    {/* List component to display a collection of items */}
    <List
      style={{
        background: 'var(--tgui--secondary_bg_color)',
        padding: 10
      }}
    >
      <ArticleDeli></ArticleDeli>
      <Divider />
      <ArticleMTProxy></ArticleMTProxy>
      <Divider />
      <ArticleTelegrahf />
    </List>
    <Body></Body>
  </AppRoot>
);
export default App;