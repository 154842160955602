import { useState } from "react";
import { Accordion, Title, Text } from "@telegram-apps/telegram-ui";

const ArticleMTProxy = () => {
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (
        expanded: boolean | ((prevState: boolean) => boolean)
    ) => {
        setExpanded(expanded);
    };

    return (
        <div>
            <Accordion id="aa" expanded={expanded} onChange={setExpanded}>
                <Accordion.Summary>
                    <Title level="3" weight="1">
                        MTProxy를 써야 하는 이유
                    </Title>
                </Accordion.Summary>
                <Accordion.Content>
                    <div style={{ padding: "10px 20px 20px" }}>
                        <Text weight="1">
                            <p>
                                다행이 텔레그램도 양심은 있는지, 텔레그램을
                                사용할떄 IP 주소를 숨길 수 있는 기능을 몇개
                                제공한다.
                            </p>
                            <p>
                                MTProxy는 텔레그램 전용 VPN이다. 텔레그램에
                                접속할 때 중간 VPN 서버를 거치기 떄문에
                                텔레그램에서도 VPN 서버 IP 만 확인할 수 있을 뿐
                                실제 우리 IP를 알 수 없다.
                            </p>
                            <p>
                                이건 텔레그램 입장에서도 사용자의 IP를 정부에
                                뒷구멍으로 보내줘봤자 VPN IP 일테니, 보안 철저
                                하다는 명성을 지키면서 뒤로는 정부에 협조하는
                                두마리 토끼를 다 잡을 수 있는 것이다.
                            </p>
                            <a href="https://core.telegram.org/mtproto/mtproto-transports">
                                https://core.telegram.org/mtproto/mtproto-transports
                            </a>
                        </Text>
                    </div>
                </Accordion.Content>
            </Accordion>
        </div>
    );
};

export default ArticleMTProxy;
