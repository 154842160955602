import { useState } from "react";
import { Accordion, Title, Text } from "@telegram-apps/telegram-ui";

const ArticleDeli = () => {
    const [expanded, setExpanded] = useState(false)

    const handleAccordionChange = (expanded: boolean | ((prevState: boolean) => boolean)) => {
        setExpanded(expanded)
    }

    return (
        <div>
            <Accordion
                id="aa"
                expanded={expanded}
                onChange={setExpanded}>
                    <Accordion.Summary>
                            <Title level="3" weight="1">텔레그램 인도 정부에 IP 제공</Title>
                    </Accordion.Summary>
                    <Accordion.Content>
                    <div
                        style={{padding: '10px 20px 20px'}}>
                                                    <Text weight="1">
                        <p>인도 델리 고등법원에서 텔레그램 사용자 IP를 공개 했다. 텔레그램이 비공식적으로 정부에 개인정보를 제출한다는 소문이 있었는데, 그 소문을 입증하는 자료중 하나 일 것이다.</p>
                        <p>이 말인 즉슨, 텔레그램을 사용하는 우리들 또한 그냥 텔레그램을 사용할 경우 IP 주소가 노출될 가능성이 있다는 것이다.</p>
                        <a href="https://www.livelaw.in/news-updates/after-court-order-telegram-discloses-phone-numbers-ip-addresses-of-users-accused-of-sharing-infringing-material-215311">
                        After Delhi High Court Ruling, Telegram Discloses Names, Phone Numbers & IP Addresses Of Users Accused Of Sharing Infringing Material
                        </a>
                        </Text>
                    
                    </div>
                    </Accordion.Content>
                </Accordion>
        </div>
    )
}

export default ArticleDeli