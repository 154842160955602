import { useState } from "react";
import { Accordion, Title, Text } from "@telegram-apps/telegram-ui";

const ArticleTelegrahf = () => {
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (
        expanded: boolean | ((prevState: boolean) => boolean)
    ) => {
        setExpanded(expanded);
    };

    return (
        <div>
            <Accordion id="aa" expanded={expanded} onChange={setExpanded}>
                <Accordion.Summary>
                    <Title level="3" weight="1">
                        Telgra.ph 익명 블로그
                    </Title>
                </Accordion.Summary>
                <Accordion.Content>
                    <div style={{ padding: "10px 20px 20px" }}>
                        <Text weight="1">
                            <p>
                                텔레그램에서 익명 블로그 서비스를 만들었다. Telegra.ph라는 서비스이다.
                            </p>
                            <p>
                                익명 블로그라는 점을 제외하고, 다른 블로그와 다르게 Telegra.ph에 한번 작성된 글은 삭제하거나, 수정할 수 없다. 따라서
                                한번 글을 써 놓으면, 평생 안전하게 글을 이용할 수 있다.
                            </p>
                            <p>
                                텔레그램 채널에서 뻘글을 쓰는것보다. Telegra.ph 서비스를 이용하는 것이 훨씬 안전하다.
                                채널은 텔레그램 신고를 통해서 중단되거나 심하면 채널을 만든 텔레그램 계정이 삭제될 수 도 있지만, Telegra.ph는 그렇지 않다.
                            </p>
                            <p>
                                단점도 있는데, Telegra.ph에 작성한 글은 검색할 수 없고 그 글 URL을 알고 있는 사람만 접속할 수 있다는 것이다. 하지만 이건
                                채널에 Telegra.ph 링크를 연결하는 것으로 해결할 수 있다.
                            </p>
                        </Text>
                    </div>
                </Accordion.Content>
            </Accordion>
        </div>
    );
};

export default ArticleTelegrahf;
