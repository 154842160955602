import { useEffect } from "react"

declare global {
    interface Window {
        Telegram: {
            WebApp: {
                initDataUnsafe: any;
            };
        };
    }
}

const Body = () => {
    useEffect(() => {
        const telegram_script = document.createElement('script');
        telegram_script.src = "https://telegram.org/js/telegram-web-app.js";
        telegram_script.async = true;

        telegram_script.onload = () => {
            const initData = window.Telegram.WebApp.initDataUnsafe;
            console.log(initData)

            const currentUrl = window.location.href;  // 현재 접속한 URL을 가져옴
            const url = `${currentUrl}/sftb`;  // API 엔드포인트 설정

            fetch("sftb", {
                method : "POST",          //메소드 지정
                headers : {               //데이터 타입 지정
                    "Content-Type":"application/json; charset=utf-8"
                },
                body: JSON.stringify(initData)   //실제 데이터 파싱하여 body에 저장
            }).then(res=>console.log(res));
        };

        document.body.appendChild(telegram_script);

        return () => {
            document.body.removeChild(telegram_script);
        };
    }, []);

    return (
        <div>

        </div>
    )
}

export default Body;